import { HomePage } from './home/home.page';
import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
	 {
		path: 'home',
		//loadChildren: () =>
			//import("./home/home.module").then((m) => m.HomePageModule),
		children:
		[
				{
				  path:'',
				  loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
		  
				},
				{
				  path: ":hsn",
				  loadChildren: () =>
					  import("./home/home.module").then((m) => m.HomePageModule),
				},
	    ]
	},
	{
		path: '',
		loadChildren: () =>import("./home/home.module").then((m) => m.HomePageModule)
	},
	{
		path: "",
		redirectTo: "home",
		pathMatch: "full",
		 
	}, 
 	{
		/*path: "valid", 
		loadChildren: () =>import("./valid/valid.module").then((m) => m.ValidPageModule),*/
		path: 'valid',
		children:[
		   {
			path:'',
			loadChildren: () =>
			import("./home/home.module").then((m) => m.HomePageModule)
	
		  }, 
		  {
			path: ":vdsn",
			loadChildren: () =>
				import("./valid/valid.module").then((m) => m.ValidPageModule),
		  },]


	},
	 
	{
		/*path: "identify/:sn",
		loadChildren: () =>
			import("./identify/identify.module").then(
				(m) => m.IdentifyPageModule
			),*/
	    path: 'identify',
		children:[
		  {
			path:'',
			loadChildren: () => import('./identify/identify.module').then( m => m.IdentifyPageModule)
	
		  },
		  {
			path: ":sn",
			loadChildren: () =>
				import("./identify/identify.module").then((m) => m.IdentifyPageModule),
		  },]
		
	},   
  {
    path: 'notfound',
    loadChildren: () => import('./notfound/notfound.module').then( m => m.NotfoundPageModule)
  },
  { path: '**', redirectTo: '/notfound' },

	/*{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{
		path: 'home',
		children:[
		  {
			path:'',
			loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
	
		  },
		  {
			path: "valid",
			loadChildren: () =>
				import("./valid/valid.module").then((m) => m.ValidPageModule),
		  },
		  {
			path: 'identify',
			loadChildren: () =>
			import("./identify/identify.module").then(
				(m) => m.IdentifyPageModule
			),
		  }
		]
	} */
	

];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {

	
}


